<template>
	<div>
		<div id="scrollbar">
            <div id="scrollbar-bg"></div>
        </div>
		<header class="header_white">
			<Navbar logoColor="true"></Navbar>
		</header>
		<section id="about" class="bg_lighgrey_pattern">
			<div id="post" class="container">
				<div class="row">
					<div class="col-12 col-lg-10 offset-lg-1 aos-init" data-aos="fade-up" data-aos-duration="1000">
            			<img class="img-fluid img--animated-soft" :src="require(`@/assets/blog/Velimir-Bulatovic-Serbian-Researchers.png`)" alt="blog" height="" width="">
						<h3 class="title_xl title_lightblue mt-4 mb-3">
							Velimir Bulatović: Znanje treba da bude dostupno svima!
						</h3>

            <div class="text_bold text_m mt-4 mb-4">
              <div class="row px-3">
                <div class="d-inline-block mr-3">
                  <img class="rounded-circle" :src="require(`@/assets/blog/milica.jpeg`)" width="40" height="40">
                </div>
                <div class="d-inline-block">
                  Milica Brković<br>
                  Mar 03, 2025
                </div>
              </div>
            </div>
						<div class="reading-time badge badge-danger d-inline-block px-2">
							<details>
							  <summary>1 minut</summary><span></span>
							</details>
						</div>
            <hr/>
						<p class="text_xl  my-3">
							Inovacija predstavlja proces pretvaranja novih ideja u vrednost, bilo kroz proizvode, usluge, poslovne modele ili druge nove načine rada. Reč je o složenom procesu koji prevazilazi puku kreativnost i izum, jer obuhvata i praktične korake neophodne za usvajanje i primenu.
							<br><br>
							A u savremenom svetu gde su inovacije pokretač razvoja, pristup pravim ljudima i relevantnim informacijama često određuje da li će određena ideja ostati samo koncept ili će postati rešenje koje oblikuje čitavu industriju.
							<br><br>
							Platforma Serbian Researchers nastala je iz potrebe da istraživačima omogući veću vidljivost, pristup resursima i prilike za saradnju sa kompanijama i institucijama. Cilj je jednostavan – omogućiti brže i lakše povezivanje stručnjaka iz različitih oblasti kako bi naučna dostignuća imala što veću primenu u praksi.
							<br><br>
							U prvom intervjuu sa osnivačima platforme Serbian Researchers, Velimir Bulatović govori o nastanku ideje, izazovima sa koji su se suočili tokom razvoja, ali i viziji za budućnost ove edukativne inicijative.
						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							1. Kako ste došli na ideju da pokrenete Serbian Researchers i koji trenutak je bio ključan za njen nastanak?
						</h3>
						<p class="text_xl  my-3">
							Sve je počelo spontano – kroz razgovore sa Lukom i Nemanjom koji su tada bili na doktorskim studijama i aktivno se bavili akademskim istraživanjima. Sa druge strane, moj rad u industriji bio je fokusiran na komercijalne istraživačke projekte koji su se oslanjali na rezultate istraživanja nekih od njihovih budućih kolega. Tokom naših razgovora, često smo nailazili na isti izazov – iako postoji ogroman potencijal za saradnju između akademske zajednice i industrije, taj most jednostavno nije postojao ili je bio previše slab da bi imao stvaran uticaj.
							<br><br>
							I upravo taj jaz između nauke i privrede me je naveo da razmišljam o tome kako bi platforma mogla da pomogne. Svedoci smo da velike kompanije poput Google-a, Microsoft-a i drugih, ulažu milijarde dolara u interne R&D centre, ali su rezultati tih istraživanja često zatvoreni i dostupni samo njima. S druge strane, naučnici u Srbiji i širom sveta svakodnevno rade na projektima koji imaju ogroman potencijal, ali nemaju dovoljno vidljivosti, finansijske podrške ili kontakata koji bi im omogućili da njihove ideje dođu do pravih ljudi.
							<br><br>
							Tada mi je postalo jasno da ne treba čekati da se stvari same promene, jer verovatno neće, već ako postoji problem, treba ga rešiti. Tako smo odlučili da pokrenemo Serbian Researchers, platformu koja bi mogla da omogući istraživačima lakši pristup resursima, projektima i ljudima koji mogu da ih podrže, bilo da su to akademske institucije, partneri iz industrije ili kolege iz sličnih oblasti.
						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							2. Koji problem konkretno rešavate i kako Serbian Researchers doprinosi naučnoj zajednici?
						</h3>
						<p class="text_xl  my-3">
							Glavni problem koji želimo da rešimo jeste nedostatak sistemskog povezivanja među istraživačima, kao i između nauke i industrije. U Srbiji, ali i širom sveta, postoji veliki broj talentovanih istraživača, ali oni često nemaju platformu na kojoj mogu da predstave svoj rad, pronađu saradnike ili dobiju pristup relevantnim projektima i finansiranju.
							<br><br>
							Danas, ako ste istraživač, pronalaženje saradnika ili investitora često zavisi od ličnih kontakata ili sporadičnih prilika koje se ukažu. Mi želimo da taj proces učinimo transparentnijim i dostupnijim. Serbian Researchers omogućava istraživačima da kreiraju svoje profile, povežu se sa drugima koji rade na sličnim temama, dobiju personalizovane preporuke za projekte i grantove i kroz saradnju promovišu rezultate svojih istraživanja.
							<br><br>
							Pored toga, cilj nam je da kompanijama i industrijskim liderima olakšamo pristup naučnoj zajednici. Inovacije ne nastaju izolovano – potrebna je sinergija akademije i privrede kako bi naučna otkrića mogla da imaju stvaran uticaj. Ako uspemo da kroz Serbian Researchers stvorimo okruženje u kojem će se ta saradnja podrazumevati, onda smo ostvarili svoju misiju.

						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							3. Koji su najveći izazovi sa kojima ste se susreli prilikom razvoja platforme?
						</h3>
						<p class="text_xl  my-3">
							Glavni izazov jeste i biće upravo pitanje angažovanja – kako motivisati istraživače da koriste platformu i vide je kao vredan alat, a ne kao još jednu mrežu koja im oduzima vreme. Naučnici su prirodno fokusirani na svoje projekte i često im nedostaje vremena za dodatne aktivnosti, osim ako one ne donose direktnu vrednost. Zbog toga smo od početka gradili platformu sa jasnim ciljem – da bude korisna, a ne samo informativna.
						</p>

<h3 class="title_m title_lightblue my-4 my-lg-5">
	4. Kakva je vaša vizija za budućnost Serbian Researchers i na čemu trenutno radite?

</h3>
<p class="text_xl  my-3">
	Naša vizija je da Serbian Researchers postane centralno mesto za povezivanje istraživača iz Srbije i dijaspore, ali i šire – da istraživači iz različitih krajeva sveta prepoznaju platformu kao vredan alat za umrežavanje i saradnju. Želimo da napravimo ekosistem koji će podržavati interdisciplinarne projekte, omogućiti bržu razmenu znanja i dati istraživačima pristup resursima koji im mogu pomoći da unaprede svoj rad.
							<br><br>
							Dugoročno, Serbian Researchers ne vidimo samo kao platformu, već kao inicijativu koja će pomoći da naučna zajednica postane bolje povezana, vidljivija i uticajnija na globalnom nivou. Verujemo da znanje treba da bude dostupno svima, a ne privilegija onih koji imaju resurse da ga zadrže za sebe. Naša misija je da pomognemo istraživačima da njihov rad ne ostane samo deo akademskih arhiva, već da ima stvaran uticaj na svet u kojem živimo.

</p>
					</div>

				</div>




			</div>
		</section>
		<Footer bg="footer_bg-red"></Footer>
	</div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

export default{
	name: "PlatformaSrpskiIstrazivaci",

	components: {
		Navbar,
		Footer
	},
	methods: {
		getWordCount(){
			const post = document.getElementById("post");
			return post.innerText.match(/\w+/g).length;
		},

		setReadingTime(){
			const avgWordsPerMin = 250;
			const readingTimeSummary = document.querySelector(".reading-time");

			let count = this.getWordCount();
			let time = Math.ceil(count / avgWordsPerMin);

			readingTimeSummary.innerText = time + " min čitanja";
		}
	},

  	mounted() {

		this.setReadingTime();

      	var bar_bg = document.getElementById("scrollbar-bg"),
			body = document.body,
			html = document.documentElement;

		bar_bg.style.minWidth = document.width + "px";

		document.getElementsByTagName("body")[0].onresize = function() {
			// Update the gradient width
			bar_bg.style.minWidth = document.width + "px";
		}

		window.onscroll = function() {
			// Change the width of the progress bar
			var bar = document.getElementById("scrollbar"),
				dw  = document.documentElement.clientWidth,
				dh  = Math.max( body.scrollHeight, body.offsetHeight,
							html.clientHeight, html.scrollHeight, html.offsetHeight ),
				wh  = window.innerHeight,
				pos = pageYOffset || (document.documentElement.clientHeight ?
								document.documentElement.scrollTop : document.body.scrollTop),
				bw  = ((pos / (dh - wh)) * 100);

			bar.style.width = bw + "%";
		}
  	}
}

</script>
